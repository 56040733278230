
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { getCourseList, deleteCourse, belongSchools } from '@/api/index'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import CreateCourse from './createCourse.vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { getInfo } from '@/api/cookie'
import companyDialog from '@/components/dialog-table.vue'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import { DownOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '课程名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '课程分类',
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const companyColums = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '单位名称',
    dataIndex: 'name',
    key: 'name'
  }
]
interface ListProps {
  search: string; // 搜索关键字(含对学校名，联系人，联系人手机）
  page: number;
  pagesize: number;
  unit_id?: number; // 筛选单元id
  tags?: string; // 选中的标签
  is_asc?: boolean; // 是否正序，T正 F反
}
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'CourseManger',
  components: {
    mangerPageHeader,
    CreateCourse,
    companyDialog,
    DownOutlined
  },
  setup () {
    const store = useStore()
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination }
    })
    const companyData = ref([])
    const searchInput = ref('')
    // 获取课程列表
    const fetchCourseHttp = (isDelete?: boolean) => {
      getCourseList({
        search: searchInput.value.trim(),
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then((res) => {
        tableData.data = res.data.map((item: { key: number; id: number }) => {
          item.key = item.id
          return item
        })
        tableData.pagination.total = res.result ? res.result : 0
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchCourseHttp()
        }
      })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchCourseHttp()
    }
    const changeData = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetchCourseHttp()
    }
    const router = useRouter()
    // 跳转编辑页面
    const goEidt = (id: number) => {
      router.push({ name: 'CreateCourse', params: { id } })
    }
    // 跳转资源编辑页面
    const goEidtResourse = (id: number) => {
      router.push({ name: 'resourseEditor', params: { id } })
    }
    const goEidtExercise = (id: number) => {
      router.push({ name: 'editCourseExercise', params: { id } })
    }
    // 删除课程
    const fetchDeleteCourse = (id: number) => {
      showConfirm({
        content: '此操作不可逆，确定删除该课程吗?',
        callBack: () => {
          deleteCourse({
            id
          }).then(() => {
            message.success('删除课程成功')
            fetchCourseHttp(true)
          })
        }
      })
    }
    // 查询所属单位
    const fetchCompanyHttp = (id: number) => {
      belongSchools({
        course_id: id
      }).then(res => {
        companyData.value = res.data.map(
          (item: any) => {
            item.key = item.id
            return item
          })
      })
    }
    // 打开所属课程弹框
    const courseDialogVisible = ref<boolean>(false)
    const dialogRef = ref()
    const openCourse = (id: number) => {
      courseDialogVisible.value = true
      // const { fetchCourseHttp } = dialogRef.value
      fetchCompanyHttp(id)
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchCourseHttp)
    // 关闭所属课程弹框
    const dialogCancle = () => {
      courseDialogVisible.value = false
    }
    const btnList: any = ref([])
    // 设置顶部导航权限
    const setNav = () => {
      if (store.state.isOfficial) {
        btnList.value = [{
          id: 1,
          btnName: '课程列表'
        },
        {
          id: 2,
          btnName: '新建课程'
        }]
      } else {
        btnList.value = [{
          id: 1,
          btnName: '课程列表'
        }]
      }
    }
    onMounted(() => {
      fetchCourseHttp()
      setNav()
    })
    return {
      goEidtResourse,
      size: ref('small'),
      btnList,
      ...toRefs(tableData),
      searchInput,
      fetchSearch,
      changeData,
      changeIndex,
      btnIndex,
      goEidt,
      fetchDeleteCourse,
      openCourse,
      courseDialogVisible,
      dialogCancle,
      dialogRef,
      companyColums,
      companyData,
      store,
      goEidtExercise
    }
  }
})
